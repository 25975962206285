<template>
    <div class="container">
        <section class="docs">
            <header class="header-top">
                <!-- <div class="header-img-box"> -->
                    <img src="../assets/logo.png" alt="">
                <!-- </div> -->
                <nav class="nav-top">
                    <el-dropdown class="drop-menu btn" @command="langChange">
                        <span class="el-dropdown-link fontColor">
                            {{ $t('msg.lang') }}<i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in options" :key="item.value" :value="item.value">{{ item.label }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div class="BtnGroup">
                        <button class="fontColor BtnBlue btn">登录</button>
                        <button type="primary " class="btn BtnWrite">注册</button>
                    </div>
                </nav>
            </header>
        </section>
        <div class="line"></div>
        <div class="main">
            <div class="menu">
                <div class="logo">
                    <strong>文档中心</strong>
                </div>
                <navMenu></navMenu>
            </div>
            <div class="body">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import navMenu from '../components/navMenu.vue'
export default {
  components: { navMenu },
  data () {
    return {
      options: [
        {
          value: 'zh',
          label: '中文'
        },
        {
          value: 'en',
          label: 'English'
        }
      ]
    }
  },
  methods: {
    langChange () {
      console.log('111')
      // 读取缓存
      const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh'
      if (lang === 'zh') {
        this.$i18n.locale = 'en'
        localStorage.setItem('lang', 'en') // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
      } else {
        this.$i18n.locale = 'zh'
        localStorage.setItem('lang', 'zh')
      }
      location.replace(location) // 刷新网页
    }
  }
}
</script>

<style scoped>
.container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.docs{
    /* width: 1200px; */
    width: 100%;
    /* margin: 0 auto; */
    margin-bottom: 30px;
}
.header-top{
    height: 64px;
    padding: 16px 0;
    box-sizing: border-box;
    margin: 0 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-top img{
    width: 210px;
}
.nav-top{
    float: right;
    width: 50%;
    text-align: right;
    font-size: 0;
}
.BtnGroup{
    display: inline-block;
    
}
.fontColor{
    color: #2b7eee;
    
}
.BtnBlue{
    border: #2b7eee 1px solid;
    background-color: white;
    border-radius: 5px;
}
.BtnWrite{
    border: #2b7eee 1px solid;
    background-color: #2b7eee;
    border-radius: 5px;
    color: white;
}
.BtnBlue:hover{
    color: white;
    border: #2b7eee 1px solid;
    background-color: #2b7eee;
}
.BtnWrite:hover{
    color: #2b7eee;
    background-color: white;
}
.btn{
    height: 32px;
    width: 72px;
    margin-left: 16px;
    text-align: center;
    line-height: 30px;
    padding: 0;
    font-size: 16px;
}

.container .main {
    flex: 1;
    /* background-color: rgb(255, 102, 0); */
    overflow: hidden;
    display: flex;
}

.container .main .menu {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-left: 45px;
    margin-right: 50px;
}

.container .main .menu .logo {
    /* height: 100px; */
    width: 100%;
    /* background-color: blueviolet; */
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
    color: #999;
    margin-bottom: 40px;
    /* justify-content: center; */
}

.container .main .menu ul {
    flex: 1;
    overflow-y: scroll;
}

.container .main .menu ul>li {
    height: 200px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000;
}

.container .main .body {
    flex: 1;
    /* background-color: rgb(255, 0, 162); */
    overflow-y: scroll;
    padding-right: 45px;
}

.line{
    width: 73%;
    /* height: 1px; */
    margin-left: 295px;
    border-bottom: 1px solid #ccc;
}

.body::-webkit-scrollbar-button{
    display:none
}

.body::-webkit-scrollbar {
    width: 7px; /* 对垂直滚动条有效 */
}

/* 定义滚动条的轨道颜色、内阴影及圆角 */
.body::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
    border-radius: 5px;
}

/* 定义滑块颜色、内阴影及圆角 */
.body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.066);
}
.body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, .2);
}
</style>
